import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './RpcStatus.css'

function gRPCStatus(props) {
  return (
    <p>In Progress</p>
  )
}

export default gRPCStatus

